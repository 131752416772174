import {
  ADMINISTRADOR,
  ADMIN_SYS,
  CONSULTA_FINANCEIRO,
  CONTABIL,
  CONTROLADORIA,
  CREDITO_COBRANCA,
  FATURAMENTO,
  FATURAMENTO_NFE,
  FINANCEIRO,
  GERENTE_OSORIO,
  LOGISTICA,
  REPRESENTANTE,
  VENDEDOR_COMERCIO,
  SUPERVISOR,
  SUPERVISOR_NACIONAL,
  EMISSOR_NFE,
  CAIXA,
  GESTAO,
  OPERACIONAL,
  VENDEDOR,
  FINANCEIRO_GERENTE,
} from "@/constants/profiles.js";

export default [
  {
    path: "/faturamento",
    name: "faturamento",
    component: () => import("@/components/home/MenuFaturamento.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONSULTA_FINANCEIRO,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        EMISSOR_NFE,
        FATURAMENTO,
        FATURAMENTO_NFE,
        FINANCEIRO,
        GERENTE_OSORIO,
        LOGISTICA,
        REPRESENTANTE,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        VENDEDOR_COMERCIO,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/cadastros",
    name: "faturamento.cadastros",
    component: () => import("@/components/faturamento/SubmenuFaturamentoCadastros.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        REPRESENTANTE,
        VENDEDOR_COMERCIO,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        EMISSOR_NFE,
        VENDEDOR,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/relatorios",
    name: "faturamento.relatorios.home",
    component: () => import("@/components/faturamento/relatorios/home-relatorios.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        REPRESENTANTE,
        VENDEDOR_COMERCIO,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        CAIXA,
        GESTAO,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/configuracoes",
    name: "faturamento.configuracoes",
    component: () => import("@/components/faturamento/SubmenuFaturamentoConfiguracoes.vue"),
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, EMISSOR_NFE, FINANCEIRO_GERENTE],
    },
  },
  {
    path: "/faturamento/nota-fiscal",
    name: "faturamento.nfe",
    component: () => import("@/components/faturamento/nfe/SubmenuFaturamentoNotaFiscal.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        REPRESENTANTE,
        VENDEDOR_COMERCIO,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/cadastros/clientes/novo",
    name: "clientes.cadastro",
    component: () => import("@/components/faturamento/cadastros/cliente/editor-cliente-geral.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        REPRESENTANTE,
        VENDEDOR_COMERCIO,
        SUPERVISOR,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        EMISSOR_NFE,
        VENDEDOR,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/cadastros/clientes/editar/:id",
    name: "clientes.edit",
    component: () => import("@/components/faturamento/cadastros/cliente/editor-cliente-geral.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        EMISSOR_NFE,
        VENDEDOR,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/cadastros/clientes",
    name: "clientes",
    component: () => import("@/components/faturamento/cadastros/cliente/clientes.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        REPRESENTANTE,
        VENDEDOR_COMERCIO,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        EMISSOR_NFE,
        VENDEDOR,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/cadastros/clientes-grupos",
    name: "clientes-grupos",
    component: () => import("@/components/faturamento/cadastros/cliente-grupo/clientes-grupos.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        EMISSOR_NFE,
        VENDEDOR,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
  },

  {
    path: "/faturamento/nota-fiscal/templates/cadastro",
    name: "templates.cadastro",
    component: () => import("@/components/faturamento/configuracoes/templates/editor-template.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        CAIXA,
      ],
    },
  },
  {
    path: "/faturamento/nota-fiscal/templates/editar/:id",
    name: "templates.edit",
    component: () => import("@/components/faturamento/configuracoes/templates/editor-template.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        CAIXA,
      ],
    },
  },
  {
    path: "/faturamento/nota-fiscal/templates",
    name: "templates",
    component: () => import("@/components/faturamento/configuracoes/templates/lista-de-templates.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        FINANCEIRO_GERENTE,
        CAIXA,
      ],
    },
  },

  {
    path: "/faturamento/configuracoes/mva/ajuste-aliquota",
    name: "ajuste.mva",
    component: () => import("@/components/faturamento/configuracoes/aliquotas/ajuste-mva.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        CONTABIL,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/configuracoes/token-ibpt",
    name: "ibpt.token",
    component: () => import("@/components/faturamento/configuracoes/api/form-ibpt.vue"),
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTABIL, EMISSOR_NFE, GESTAO, FINANCEIRO_GERENTE],
    },
  },
  {
    path: "/faturamento/configuracoes/margem-preco",
    name: "margem.precos",
    component: () => import("@/components/faturamento/configuracoes/margem-preco/margem-preco.vue"),
    meta: {
      authorize: [CONTROLADORIA, ADMIN_SYS, GESTAO],
    },
  },
  {
    name: "faturamento.nota-fiscal.gerenciar.novo",
    path: "/faturamento/nota-fiscal/gerenciar/novo",
    component: () => import("@/components/faturamento/nfe/NovaNFe.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        VENDEDOR_COMERCIO,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        EMISSOR_NFE,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/nota-fiscal/gerenciar",
    name: "nfe.gerenciar-nfe",
    component: () => import("@/components/faturamento/nfe/gerenciar-nfe.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        VENDEDOR_COMERCIO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/nota-fiscal/editar/:id",
    name: "nfe.edit",
    component: () => import("@/components/faturamento/nfe/gerenciar-nfe-show.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        SUPERVISOR_NACIONAL,
        FATURAMENTO,
        VENDEDOR_COMERCIO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        EMISSOR_NFE,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/orcamento/editar/:id",
    name: "orcamento.edit",
    component: () => import("@/components/faturamento/nfe/gerenciar-orc-show.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        SUPERVISOR_NACIONAL,
        VENDEDOR_COMERCIO,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        EMISSOR_NFE,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/nota-fiscal/gerenciar/:id/edit",
    name: "nfe.editar",
    component: () => import("@/components/faturamento/nfe/NovaNFe.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        VENDEDOR_COMERCIO,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        EMISSOR_NFE,
        CAIXA,
        GESTAO,
        OPERACIONAL,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/cadastros/servicos/gerenciar",
    name: "cadastro.servico.gerenciar",
    component: () => import("@/components/faturamento/cadastros/servico/gerenciarservico.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        VENDEDOR_COMERCIO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        CAIXA,
        GESTAO,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/cadastros/servicos/novo",
    name: "cadastro.servico.novo",
    component: () => import("@/components/faturamento/cadastros/servico/novoservico.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        VENDEDOR_COMERCIO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        CAIXA,
        OPERACIONAL,
        GESTAO,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/cadastros/servicos/editar/:id",
    name: "cadastro.servico.editar",
    component: () => import("@/components/faturamento/cadastros/servico/novoservico.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        VENDEDOR_COMERCIO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        CAIXA,
        GESTAO,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/os",
    name: "os",
    component: () => import("@/components/faturamento/SubmenuFaturamentoOrdemServico.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        VENDEDOR_COMERCIO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        CAIXA,
        GESTAO,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/os/novo",
    name: "os.novo",
    component: () => import("@/components/faturamento/os/novaos.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        VENDEDOR_COMERCIO,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        EMISSOR_NFE,
        CAIXA,
        GESTAO,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/os/editar/:id",
    name: "os.editar",
    component: () => import("@/components/faturamento/os/novaos.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        VENDEDOR_COMERCIO,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        EMISSOR_NFE,
        OPERACIONAL,
        CAIXA,
        GESTAO,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/os/gerenciar",
    name: "os.gerenciar",
    component: () => import("@/components/faturamento/os/gerenciar-os.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        VENDEDOR_COMERCIO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        EMISSOR_NFE,
        CAIXA,
        OPERACIONAL,
        GESTAO,
        VENDEDOR,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/relatorios/produtos/grupos",
    name: "faturamento.relatorios.gruposprodutos",
    component: () => import("@/components/faturamento/relatorios/relatorio-grupos-produtos.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
      ],
    },
  },

  {
    path: "/faturamento/relatorios/rentabilidade/linhas",
    name: "faturamento.relatorios.rentabilidadelinhas",
    component: () => import("@/components/faturamento/relatorios/relatorio-rentabilidade-linhas.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
  },

  {
    path: "/faturamento/relatorios/rentabilidade/linhas",
    name: "faturamento.relatorios.rentabilidadelinhas",
    component: () => import("@/components/faturamento/relatorios/relatorio-rentabilidade-linhas.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
  },

  {
    path: "/faturamento/relatorios/vendas/vendedores",
    name: "faturamento.relatorios.vendas-vendedores",
    component: () => import("@/components/faturamento/relatorios/vendas-vendedores.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/relatorios/metas/vendedores",
    name: "faturamento.relatorios.metas-vendedores",
    component: () => import("@/components/faturamento/relatorios/metas-vendedores.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        VENDEDOR,
        FINANCEIRO_GERENTE,
        EMISSOR_NFE,
        GESTAO,
      ],
    },
  },
  {
    path: "/faturamento/relatorios/grupos-produtos-periodo",
    name: "faturamento.relatorios.gruposprodutosperiodo",
    component: () => import("@/components/faturamento/relatorios/relatorio-grupos-produtos-periodo.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/relatorios/total-vendas-representante-cliente",
    name: "faturamento.relatorios.total-vendas-representante-cliente",
    component: () => import("@/components/faturamento/relatorios/relatorio-vendas-representante-cliente.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/relatorios/inativos",
    name: "faturamento.relatorios.inativos",
    component: () => import("@/components/faturamento/relatorios/inativos.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/relatorios/positivacao",
    name: "faturamento.relatorios.positivacao",
    component: () => import("@/components/faturamento/relatorios/positivacao.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/relatorios/positivacao/cliente",
    name: "faturamento.relatorios.positivacao-cliente",
    component: () => import("@/components/faturamento/relatorios/positivacao-cliente.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/relatorios/positivacao/produto",
    name: "faturamento.relatorios.positivacao-produto",
    component: () => import("@/components/faturamento/relatorios/positivacao-produto.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/relatorios/representante/grupo",
    name: "faturamento.relatorios.grupo-representante",
    component: () => import("@/components/faturamento/relatorios/grupo-representante.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        GERENTE_OSORIO,
        FINANCEIRO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/relatorios/custo",
    name: "faturamento.relatorios.custo",
    component: () => import("@/components/controladoria/relatorios/custo.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        CONTABIL,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
        GESTAO,
      ],
    },
  },
  {
    path: "/faturamento/relatorios/rentabilidade",
    name: "faturamento.relatorios.rentabilidade",
    component: () => import("@/components/controladoria/relatorios/rentabilidade.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        CONTABIL,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
      ],
    },
  },
  {
    path: "/faturamento/descontos/limite-descontos",
    name: "faturamento.descontos.limite-descontos",
    component: () => import("@/components/faturamento/vendas/limite-descontos.vue"),
    meta: {
      authorize: [ ADMIN_SYS, CONTROLADORIA, SUPERVISOR_NACIONAL],
    },
  },
  {
    path: "/faturamento/precos/descontos",
    name: "faturamento.precos.descontos",
    component: () => import("@/components/faturamento/vendas/descontos.vue"),
    meta: {
      authorize: [, ADMIN_SYS, CONTROLADORIA, SUPERVISOR_NACIONAL,],
    },
  },
  {
    path: "/faturamento/precos/gerenciar-descontos",
    name: "faturamento.precos.gerenciar-descontos",
    component: () => import("@/components/faturamento/vendas/gerenciar-descontos.vue"),
    meta: {
      authorize: [ ADMIN_SYS, SUPERVISOR_NACIONAL, CONTROLADORIA],
    },
  },
  {
    path: "/faturamento/relatorios/produtosmaisvendidos",
    name: "faturamento.relatorios.produtosmaisvendidos",
    component: () => import("@/components/faturamento/relatorios/produtos-mais-vendidos.vue"),
    meta: {
      authorize: [ADMIN_SYS, CONSULTA_FINANCEIRO, SUPERVISOR, SUPERVISOR_NACIONAL, FINANCEIRO_GERENTE, GESTAO],
    },
  },
  {
    path: "/faturamento/precos/tabela-precos",
    name: "faturamento.precos.tabelaprecos",
    component: () => import("@/components/faturamento/vendas/supervisor/tabela-precos.vue"),
    meta: {
      authorize: [ADMINISTRADOR, SUPERVISOR, SUPERVISOR_NACIONAL, ADMIN_SYS, FINANCEIRO_GERENTE],
    },
  },
  {
    path: "/backup-precos",
    name: "backup.precos",
    component: () => import("@/components/faturamento/vendas/backup-precos.vue"),
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, FINANCEIRO_GERENTE],
    },
  },
  {
    path: "/faturamento/caixa",
    name: "faturamento.caixa",
    component: () => import("@/components/faturamento/caixa/ViewCaixa.vue"),
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, CAIXA, GESTAO, OPERACIONAL, FINANCEIRO_GERENTE],
    },
  },
];
